import React, { useState } from "react";
import styled from "styled-components";
import MapComponent from "./components/map";
import Results from "./components/results";

const Root = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  font-family: "Openserif", "Roboto", sans-serif;
`;

function App() {
  const [map, setMap] = useState<google.maps.Map>();
  const [places, setPlaces] = useState<google.maps.places.PlaceResult[]>();
  return (
    <Root>
      <MapComponent map={map} setMap={setMap} onUpdate={setPlaces} />
      <Results map={map} places={places} />
    </Root>
  );
}

export default App;
