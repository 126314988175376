declare global {
  interface Window {
    dataLayer: any;
  }
}

window.dataLayer = window.dataLayer || [];

const setGoogleTMEvent = (event: string) => {
  try {
    window.dataLayer.push({ event });
  } catch (error) {
    console.log(error);
  }
};

export default setGoogleTMEvent;
