import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";
import { GoogleMap, useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import MapMarker from "./map-marker";
import { mapRef } from "../map";
import getDataAttributes from "../utils/get-data-attribe";

const Root = styled.div``;

const containerStyle = {
  width: "100%",
  height: "600px",
};

const defaultCenter = {
  lat: 53.14118,
  lng: 8.21467,
};

interface MapComponentProps {
  onUpdate: (result: google.maps.places.PlaceResult[]) => void;
  map?: google.maps.Map;
  setMap: (map?: google.maps.Map) => void;
}

const MapComponent = ({ onUpdate, map, setMap }: MapComponentProps) => {
  const mapsApiKey = getDataAttributes(
    "pharmacies-finder",
    "mapsapikey",
    "AIzaSyB-xXjtGIIwuOnGDQPMvVL8I06rgNyNzsA"
  );
  const [libraries] = useState<Libraries>(["places"]);
  const [searchRef, setSearchBox] = useState<any>();
  const [center, setCenter] = useState<any>(defaultCenter);
  const [markers, setResult] = useState<any>([]);
  const [activeMarker, setActiveMarker] = useState<google.maps.places.PlaceResult>();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: mapsApiKey,
    libraries,
  });

  const fitMap = (place: google.maps.places.PlaceResult) => {
    // if (place?.geometry?.location) {
    //   setCenter(place?.geometry?.location);
    //   map?.setZoom(13);
    // }

    if (place?.geometry?.viewport) {
      map?.fitBounds(place.geometry.viewport);
    }
    getResults(map);
  };

  const searchByText = (inputText: string) => {
    if (map) {
      const service = new google.maps.places.PlacesService(map);
      service.findPlaceFromQuery(
        {
          query: inputText,
          fields: ["name", "place_id", "geometry", "types"],
        },
        (result) => {
          if (result.length) {
            fitMap(result[0]);
          }
        }
      );
    }
  };

  const getResults = useCallback(
    (srcMap) => {
      if (srcMap) {
        const request = {
          types: ["pharmacy"],
          location: srcMap.getCenter(),
          radius: 10000,
          reankBy: "DISTANCE",
        };
        const service = new google.maps.places.PlacesService(srcMap);
        service.nearbySearch(request, async (results) => {
          setResult(results);
          onUpdate(results);
        });
      }
    },
    [onUpdate]
  );

  const onLoad = useCallback(
    (map) => {
      navigator?.geolocation.getCurrentPosition(({ coords: { latitude: lat, longitude: lng } }) =>
        setCenter({ lat, lng })
      );
      setMap(map);
      getResults(map);
    },
    [getResults, setMap]
  );

  const onUnmount = React.useCallback(() => setMap(), [setMap]);
  return (
    <Root>
      {isLoaded ? (
        <GoogleMap
          ref={mapRef}
          mapContainerStyle={containerStyle}
          center={center}
          options={{
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            minZoom: 12,
            maxZoom: 16,
          }}
          onDragStart={() => {}}
          onDragEnd={() => {
            getResults(map);
          }}
          zoom={13}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          <Autocomplete
            onLoad={(ref) => setSearchBox(ref)}
            restrictions={{ country: "de" }}
            onPlaceChanged={() => {
              if (searchRef != null) {
                const place = searchRef.getPlace();
                if (place) {
                  fitMap(place);
                }
              }
            }}
            options={{}}
          >
            <input
              type="text"
              placeholder="Ort, PLZ, Adresse ..."
              onKeyDown={(event) => {
                if (event.keyCode === 13) {
                  searchByText(event?.currentTarget?.value || "");
                  event.preventDefault();
                  return false;
                }
              }}
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `260px`,
                padding: `10px 15px`,
                borderRadius: `4px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `16px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: "absolute",
                fontFamily: "Openserif book",
                left: "20px",
                top: "20px",
              }}
            />
          </Autocomplete>
          {markers.map((row: any) => {
            return (
              <MapMarker
                isActive={activeMarker?.place_id === row.place_id}
                onClick={setActiveMarker}
                data={row}
                map={map}
                key={row.place_id}
              />
            );
          })}
        </GoogleMap>
      ) : null}
    </Root>
  );
};

export default MapComponent;
